import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueSocket from "./plugins/socket";
// import VueVideoStream from "./plugins/videoStream";
// import AgoraVideoStream from "./plugins/agoraVideoStream";
import TwilioVideoStream from "./plugins/twilioVideoStream";
import ZoomVideoStream from "./plugins/zoomVideoStream";
import browserDetect from "vue-browser-detect-plugin";
import VueChatScroll from "vue-chat-scroll";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import i18n from "./i18n";

require("moment/locale/ar");

Vue.use(
  new VueSocket({
    url: process.env.VUE_APP_SOCKET_URL,
  })
);

// Vue.use(new VueVideoStream());
// Vue.use(new AgoraVideoStream());
Vue.use(new TwilioVideoStream());
Vue.use(new ZoomVideoStream());

Vue.use(require("vue-moment"));

Vue.use(browserDetect);

// https://www.npmjs.com/package/vue-chat-scroll
Vue.use(VueChatScroll);

Vue.config.productionTip = false;

export const eventBus = new Vue();

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

if (process.env.VUE_APP_VIDEO_SERVER_HOST === "api-legacy.talktime.ae") {
  let fb = document.createElement("script");
  fb.appendChild(
    document.createTextNode(`
    !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '679341013445072');
      fbq('track', 'PageView');
      `)
  );

  let fbVerification = document.createElement("meta");
  fbVerification.name = "facebook-domain-verification";
  fbVerification.content = "8fuuxxt08jwvgymrheseh64bhuykhy";

  let gtagScript = document.createElement("script");
  gtagScript.async = true;
  gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=UA-222835235-1";

  let gtagScript1 = document.createElement("script");
  gtagScript1.appendChild(
    document.createTextNode(`
        window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-222835235-1');
    `)
  );

  let gtagScript2 = document.createElement("script");
  gtagScript2.appendChild(
    document.createTextNode(`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-T5SNFDX');
    `)
  );

  document.head.append(
    fb,
    fbVerification,
    gtagScript,
    gtagScript1,
    gtagScript2
  );

  //   window.document.head.innerHTML += `
  //
  // <!-- Meta Pixel Code -->
  // <script>
  //     !function(f,b,e,v,n,t,s)
  //     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //         n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //         if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //         n.queue=[];t=b.createElement(e);t.async=!0;
  //         t.src=v;s=b.getElementsByTagName(e)[0];
  //         s.parentNode.insertBefore(t,s)}(window, document,'script',
  //         'https://connect.facebook.net/en_US/fbevents.js');
  //     fbq('init', '679341013445072');
  //     fbq('track', 'PageView');
  // </script>
  //
  // <noscript><img height="1" width="1" style="display:none"
  //                src="https://www.facebook.com/tr?id=679341013445072&ev=PageView&noscript=1"
  // /></noscript>
  // <!-- End Meta Pixel Code -->
  //
  // <meta name="facebook-domain-verification" content="8fuuxxt08jwvgymrheseh64bhuykhy" />
  //
  // <!-- Global site tag (gtag.js) - Google Analytics -->
  // <script async src="https://www.googletagmanager.com/gtag/js?id=UA-222835235-1"></script>
  //
  // <script>
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(){dataLayer.push(arguments);}
  //     gtag('js', new Date());
  //
  //     gtag('config', 'UA-222835235-1');
  // </script>
  //
  // `
}

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://ca55384f081648ff9c8bf105c592ddc7@o1346452.ingest.sentry.io/6649586",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["app.talktime.ae", "app-dev.talktime.ae", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
